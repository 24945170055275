import Plyr from './plugins/plyr';
import './global/global';
import './plugins/plyr.polyfilled';
import './plugins/fslightbox';

document.addEventListener('DOMContentLoaded', initMainPage);

function initMainPage(){
    initOffer();
}

function initOffer(){
    const player = new Plyr('.offer__video', {
        controls: [],
        muted: true,
        clickToPlay: false,
        loop: {
            active: true
        },
        fullscreen: {
            enabled: false
        }
    });
    setTimeout(() => player.play(), 200);
}
