import Notify from "./notify";
import "../plugins/wow";
import "../plugins/modal";
import Modals from "../plugins/modal";
import IMask from "../plugins/imask";

window.onpageshow = function(event) {
    if (event.persisted) {
        window.location.reload(); 
    }
};


document.addEventListener('DOMContentLoaded', () => {
    initHeader();
    initLinks();
    Modals.init();
    initContactForms();
    if(window.scrollY > 0){
        document.querySelectorAll('header.header .wow').forEach(x => x.classList.remove('wow'));
    }
    new WOW().init();
    initContactButton();
    initControls();
});

function initControls(){
    const maskOptions = {
        mask: '+{38} (000) 000-00-00'
    };
    document.querySelectorAll('input[type="tel"]').forEach(x => IMask(x, maskOptions));
}

function initContactButton(){
    const button = document.querySelector('.contact-button');
    if(!button){
        return;
    }

    if(window.innerWidth < 980){
        button.removeEventListener('click', onClickContactButton);
    }else{
        button.addEventListener('click', onClickContactButton);
    }

    window.addEventListener('resize', e => {
        if(window.innerWidth < 980){
            button.removeEventListener('click', onClickContactButton);
        }else{
            button.addEventListener('click', onClickContactButton);
        }
    })
}

function onClickContactButton(e){
    e.preventDefault();
    Modals.show('modal-request', 'Кнопка віджету');
}

function initLinks(){
    const header = document.querySelector('header.header');
    document.querySelectorAll('a[href^="#"]:not([href^=mailto]):not([href^=tel]), a[href^="/#"]:not([href^=mailto]):not([href^=tel])').forEach(anchor => {
        anchor.addEventListener('click', e => {
            e.preventDefault();

            const href = e.currentTarget.getAttribute('href').replace('/', '');
            if(href === '#'){
                return;
            }
            const target =  document.querySelector(href);

            if(!target){
                location.href = e.currentTarget.getAttribute('href');
                return;
            }
            let offset = target.offsetTop; //высота от элемента к верху 

            if(target.dataset.scrollOffset){
                offset -= parseInt(target.dataset.scrollOffset)
            }
            offset -= header.getBoundingClientRect().height - 5;
            window.scrollTo({ 
                top: offset, 
                behavior: 'smooth'
            });
        });
    });
}

function initHeader(){
  const htmlElement = document.querySelector('html');
    const header = document.querySelector('.header');

    header.querySelector('.header__toggle').addEventListener('click', e => {
        header.classList.toggle('header_show');
        if(header.classList.contains('header_show')){
          htmlElement.classList.add('scroll-disabled');
        }else{
          htmlElement.classList.remove('scroll-disabled');
        }
    })

    header.querySelector('.header__menu').addEventListener('click', e => {
        header.classList.remove('header_show');
        htmlElement.classList.remove('scroll-disabled');
    })
    
    window.addEventListener('resize', e => htmlElement.classList.remove('scroll-disabled'));

    header.dataset.scroll = window.scrollY > 0;

    window.addEventListener('scroll', e => {
        header.dataset.scroll = window.scrollY > 0;
        header.classList.remove('header_show');
        htmlElement.classList.remove('scroll-disabled');
    });
}

function initContactForms(){
    document.querySelectorAll('.contacts-form').forEach(form => {
        form.addEventListener('submit', e => {
            e.preventDefault();

            const data = new FormData(form);
            showLoader()

            fetch(form.action, {
                method: 'POST',
                body: data
            }).then(x => x.json())
            .then(response => {
                if(response.isError){
                    Notify.error(response.message);
                    return;
                }
                form.querySelectorAll('input:not([type="hidden"]):not([type="submit"]), textarea').forEach(x => x.value = '');
                Modals.show('modal-success');
            })
            .finally(x => {
                hideLoader()
            })
        })
    });
}

function showLoader(){
    const loader = document.querySelector('.loader');
    if(!loader){
        return;
    }
    loader.classList.add('loader_show');
    document.querySelector('html').classList.add('scroll-disabled');
}

function hideLoader(){
    const loader = document.querySelector('.loader');
    if(!loader){
        return;
    }
    loader.classList.remove('loader_show');
    document.querySelector('html').classList.remove('scroll-disabled');
}

//баг на IOS
document.body.addEventListener('touchstart', e => {});