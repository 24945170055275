export default class Modals{
    static init() {
        document.body.addEventListener('click', e => {
            const isClose = e.target.closest('[data-modal-action="close"]');
            if(isClose){
                Modals.closeAll();
            }
        })
        document.addEventListener('click', e => {
            const control = e.target.closest('[data-modal]');
            if(!control){
                return;
            }
            e.preventDefault();
            Modals.show(control.dataset.modal, control.dataset.modalTarget);
        });
    }
    static show(id, target = ''){
        const modal = document.querySelector(`.modal[data-modal-id="${id}"]`);
        if(!modal){
            return;
        }
        Modals.closeAll();
        
        const targetInput = modal.querySelector('input[name="target"]');
        if(targetInput){
            targetInput.value = target;
        }
        document.querySelector('html').classList.add('scroll-disabled');
        modal.classList.add('modal_show');
        const overlay = document.querySelector('.overlay');
        overlay.classList.add('overlay_show');
        modal.dispatchEvent(new Event('modal-show', {
                "bubbles" : true, 
                "cancelable" : false
            }));
    }
    static closeAll(){
        document.querySelectorAll('.modal_show')
                    .forEach(m => {
                        m.classList.remove('modal_show');
                        m.dispatchEvent(new Event('modal-close', {
                            "bubbles" : true, 
                            "cancelable" : false
                        }));
                    });
        
        const overlay = document.querySelector('.overlay');
        overlay.classList.remove('overlay_show');        
        document.querySelector('html').classList.remove('scroll-disabled');
    }
}